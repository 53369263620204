import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
} from '@coreui/react';
import routes from '../../routes';
import DefaultHeader from './DefaultHeader';
import PickProblem from "../../views/Pages/PickProblem/PickProblem";
import PrivateTeacher from "../../views/Pages/PrivateRoute/PrivateTeacher";
import axios from 'axios';
import { getUserId } from '../../core/GetRoles';
import * as ApiConfig from './../../api/ApiConfig'




class DefaultLayout extends Component {
  config = {
    headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') }
  };
  componentDidMount() {
    let t = this
    const userId = getUserId();
    // axios.get(ApiConfig.API_URL + '/users/' + userId, this.config).then(res => {
    //   if (res.data.id !== '') {
    //     t.setState({
    //       info: res.data
    //     });
    //     localStorage.setItem('student_id', res.data.student_id)
    //   }
    // }).catch(error => {
    //   console.log(error)
    // });
    axios.get(ApiConfig.API_URL + '/users/' + userId + '/courses', this.config)
        .then(function (response) {
          response.data.semesters.map((s) => {
            s.course.map(c => {
              // console.log("ID__", c.course_id)
              localStorage.setItem('tempCourse_id', c.course_id);
              localStorage.setItem('presentCourse_id', c.course_id);
              // localStorage.setItem('presentCode', c.code);
            })
          });
        })
        .catch(function (error) {
          console.log(error);
    });
  }



  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component {...props} />
                  )} />)
                    : (null);
                },
                )}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
          {/* <AppAside fixed hidden>
            <DefaultAside />
          </AppAside> */}
        </div>
        {/* <AppFooter>
          <DefaultFooter />
        </AppFooter> */}
      </div>
    );
  }
}

export default DefaultLayout;
