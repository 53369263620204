import React, { Component } from 'react';
import './index.css'
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Fade, Row } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/vi';

class Week extends Component {
    constructor(props) {
        super(props);
        moment.locale('vi');
    }

    changeWeek = () => {
        const id = this.props.index;
        this.props.changeWeek(id);
    }
    changeTime = (props) => {
        const id = this.props.index;
        if (typeof (props) === 'object') {
            // console.log(props.toISOString());
            this.props.changeDeadLine(id, props.toISOString());
        }
    }
    removeItem = (id) => {
        const idweek = this.props.index;
        this.props.removeItem(idweek, id);
    }
    onSave = () => {
        this.props.save();
    }
    render() {
        return (
            <div>
                <CardHeader style={{ background: this.props.check == this.props.index ? "#C8CED3" : null }}>
                    <label style={{ padding: 0, margin: 0, cursor: "pointer", width: "100%" }}>
                        <h5>
                            <b><input checked={this.props.check == this.props.index} onChange={this.changeWeek} name="week" type="radio"></input>TUẦN {this.props.index + 1}</b>
                            <span>- Deadline: <Datetime onChange={this.changeTime} defaultValue={moment(this.props.data.deadline).format('L LT')} /></span>
                            <div className="pick tooltip-custom float-right">
                                <button className="btn custom-btn" onClick={this.onSave}><i className="fa fa-save" ></i></button>
                                <span className="tooltiptext-custom">Lưu bài tập</span>
                            </div>
                        </h5>
                    </label>
                </CardHeader>
                <div className="content">
                    {
                        this.props.data.problems.map((obj, key) => {
                            return <div title={"Cấp độ: " + obj.level} key={key} onClick={() => { this.removeItem(key) }} className="exercise"><i className="fa fa-trash"></i>{obj.title}</div>
                        }
                        )
                    }
                </div>
            </div>
        );
    }
}
export default Week;
