import React, { Component } from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, NavbarToggler } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/logo-mini.png'
import axios from 'axios';
import * as ApiConfig from './../../api/ApiConfig'
import { getCurrentRoles, getUserId } from '../../core/GetRoles'
import ChatList from '../../views/ChatScreen/ChatList'
import './Style.css'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: '',
      menu: false,
      course: localStorage.getItem('presentCourse_id'),
      isOpenSidebar: false,
      totalUnreadConv: 0,
      isShowChat: false,
      isProfessor: false
    }
    this.config = {
      headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') }
    };
  }
  // componentWillMount() {
  //   let t = this;
  //   new Promise((resolve, reject) => {
  //     const userId = getUserId();
  //     axios.get(ApiConfig.API_URL + '/users/' + userId + '/courses', this.config)
  //       .then(function (response) {
  //         response.data.semesters.map((s) => {
  //           s.course.map(c => {
  //             // console.log("ID__", c.course_id)
  //             localStorage.setItem('tempCourse_id', c.course_id);
  //             localStorage.setItem('presentCourse_id', c.course_id);
  //             // localStorage.setItem('presentCode', c.code);
  //             t.setState({
  //               course: c.course_id
  //             })
  //           })
  //         });
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   })
  // }
  componentDidMount() {
    let t = this;
    if (t.isLectureRole()) {
    new Promise((resolve, reject) => {
      axios.get(ApiConfig.API_URL + '/users/is-professor', this.config)
        .then(function (res) {
          if (res.status == 200) {
            let data = res.data;
            t.setState({
              isProfessor: data.number
            });
          }
        })
        .catch(err => console.log(err))
        this.countUnreadConv();
    });
  }
  this.setStudentId();

    // this.identifyLecture();

  }
  // ----------------------------handle click outside menu--------------------------------//
  componentWillMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleOutsideClick = (event) => {
    if (!this.refs.megaMenu.contains(event.target)) {
      this.setState({
        menu: false
      });
    }
    if (this.refs.sideBarChatScreen && !this.refs.sideBarChatScreen.contains(event.target)) {
      this.setState({
        isOpenSidebar: false
      });
      document.getElementById("body").classList.remove('overflow-hidden');
    }
  }
  //-----------------------------------------------------------------------------//

  Logout = () => {
    localStorage.clear();
    window.location.replace("#/login");
  };

  isLectureRole = () => {
    let roles = getCurrentRoles();
    if (roles === 'LECTURE') {
      return true;
    }
    return false;
  };

  // isProfessorRole = () => {

  // }
  openMenu = () => {
    this.setState({
      menu: !this.state.menu
    });
  }

  onGetProfile = () => {
    setTimeout(() => {
      window.location.reload();
    }, 0);
    let roles = getCurrentRoles();
    if (roles === 'LECTURE') {
      // localStorage.setItem('roles', roles);
    }
  }

  showChat = () => {
    if (this.isLectureRole()) {
      return <div>
        <img src="/assets/icons/chat-icon.png" width="25" />
        <div style={{ position: "absolute", color: "#0ebcb7", top: "51%", left: "45%", transform: "translate(-50%, -50%)", fontWeight: "700" }}>{this.state.totalUnreadConv}</div>
      </div>
    }
  }

  identifyLecture = () => {
    let self = this;
    axios.get(ApiConfig.API_URL + '/users/isLecture', this.config)
      .then(function (res) {
        if (res.status == 200) {
          let data = res.data;
          // console.log(data)
          self.setState({
            isShowChat: data
          })
        }
      })
      .catch(err => console.log(err))
  }
  setStudentId = () => {
    let t = this
    const userId = getUserId();
    axios.get(ApiConfig.API_URL + '/users/' + userId, this.config).then(res => {
      if (res.data.id !== '') {
        t.setState({
          info: res.data
        });
        localStorage.setItem('student_id', res.data.student_id)
      }
    }).catch(error => {
      console.log(error)
    });
  }

  countUnreadConv = () => {
    let self = this;
    let totalUnreadConv = 0;
    axios.get(ApiConfig.API_URL + '/chat/read-conversation', this.config)
      .then(function (res) {
        if (res.status == 200) {
          let data = res.data;
          // console.log(data)
          for (let i = 0; i < data.length; i++) {
            if (!data[i].status) {
              totalUnreadConv++;
            }
          }
          self.setState({
            totalUnreadConv: totalUnreadConv
          })
        }
      })
      .catch(err => console.log(err))
  }

  render() {
    // var link = '#/course/' + this.state.course;
    // if (localStorage.getItem("presentCourse_id") !== null) {
    //   link = '#/course/' + localStorage.getItem("presentCourse_id")
    // }
    const userId = getUserId();
    let linkProfile = '#/profile/' + userId;
    var photo = this.state.info.profile_pic;

    let isLectureRole = this.isLectureRole();
    const { children, ...attributes } = this.props;
    return (
      <React.Fragment>
        <div ref="megaMenu">
          <NavbarToggler className="d-lg-none" display="md" onClick={this.openMenu} />
        </div>
        <AppNavbarBrand
          full={{ src: logo, height: 40, alt: 'OASIS' }}
          minimized={{ src: logo, width: 30, height: 30, alt: 'OASIS' }}
          href='/'
        />
        <div className={this.state.menu ? "nav-menu" : ""} >
          <Nav className="d-md-down-none" navbar>
            {!isLectureRole ?
              <NavItem className="px-3">
                <NavLink href="#/dashboard">Trang chủ</NavLink>
              </NavItem>
              : ''}
            <NavItem className="px-3">
              <NavLink href="#/submissions">Bài Nộp</NavLink>
            </NavItem>
            <NavItem className="px-3" >
              <NavLink href="#/ranking">Xếp Hạng</NavLink>
            </NavItem>
            {isLectureRole ?
              <NavItem className="px-3" >
                <NavLink href="#/statistic">Thống Kê</NavLink>
              </NavItem>
              : ''}

            {isLectureRole & !this.state.isProfessor ?
              <NavItem className="px-3" >
                <NavLink href="#/manage-course">Quản Lý Lớp Học</NavLink>
              </NavItem>
              : ''}
            {isLectureRole ?
              <NavItem className="px-3" >
                <NavLink href="#/check-sourcecode">Kiểm tra mã nguồn</NavLink>
              </NavItem>
              : ''}
          </Nav>
        </div>
        <Nav className="ml-auto" navbar>
          {isLectureRole ?
            <div className="position-relative mr-2" style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ isOpenSidebar: true });
                document.getElementById("body").classList.add('overflow-hidden');
              }}>
              {this.showChat()}
            </div>
            :
            null
          }
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <b className="username">{this.state.info.name}</b>
              <span style={{ "backgroundImage": "url(" + photo + ")" }} className="avatar-img">&nbsp;</span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center"><strong>Tài khoản</strong></DropdownItem>
              <DropdownItem href={linkProfile} onClick={this.onGetProfile}><i className="fa fa-user"></i> Trang cá nhân</DropdownItem>
              <DropdownItem href="#/update-password"><i className="fa fa-wrench"></i> Cập nhật mật khẩu</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={this.Logout}><i className="fa fa-lock"></i> Thoát</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        {this.state.isOpenSidebar
          ?
          <div>
            <div className="sideBar" ref="sideBarChatScreen">
              <ChatList></ChatList>
              <button className="close-sidebar-btn"
                onClick={() => {
                  this.setState({ isOpenSidebar: false });
                  document.getElementById("body").classList.remove('overflow-hidden');
                }}>
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </button>
            </div>
            <div className="layer"></div>
          </div>
          :
          null
        }
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
