import React, { Component } from 'react';
import './Style.css';
import axios from "axios/index";
import * as ApiConfig from '../../api/ApiConfig';
import { getUserId } from '../../core/GetRoles';
import ReactTooltip from 'react-tooltip';
import { takeRightWhile } from 'lodash';
import ChatMember from './ChatMember';
import { setGlobalCssModule } from 'reactstrap/lib/utils';

const currentUserId = getUserId();

var classNames = require('classnames');
var config = {
    headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') }
};
// console.log(config.headers);
class ChatConversation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            msgInput: '',
            messages: [],
            isOpenMember: false,
            page : 0,
            size : 30,
            totalPage : 0
        }
    }

    componentDidMount() {
        this.getMsgs(this.props.participantID ? this.props.participantID : 0, this.state.page, this.state.size)
    }
    sendMsg = (participantID, msg) => {
        if (msg.trim().length !== 0) {
            let self = this;
            let data = {
                message: msg,
                receiverId: participantID
            }
            this.setState({
                msgInput: ''
            })
            axios.post(ApiConfig.API_URL + `/chat/send`, {
                receiverId: data.receiverId ? data.receiverId : 0,
                message: data.message
            }, config)
                .then(res => {
                    if (res.status == 200) {
                        let messages = this.state.messages;
                        messages.push({
                            sendId: currentUserId,
                            message: msg
                        })
                        self.setState({
                            messages: messages
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })  
        }
        
    }

    getMsgs = (participantID, page, size) => {
        let self = this;
        participantID = participantID ? participantID : 0;
        axios.get(ApiConfig.API_URL + `/chat/read-message?receiverId=${participantID}&page=${page}&size=${size}`, config)
            .then( (res) => {
                let arr = [];
                arr = res.data.content;
                let count = page + 1;
                let mgs = [];
                mgs = self.state.messages;

                self.setState({
                    messages:  arr.reverse().concat(mgs),
                    page : count,
                    totalPage : res.data.totalPages
                });
            })
            .catch(err => console.log(err))
    }

    openChatList = () => {
        this.props.openChatList();
    }


    componentDidUpdate() {
        window.setTimeout(function () {
            var elem = document.getElementById('messages');
            elem && (elem.scrollTop = elem.scrollHeight);
        }, 300);
    }

    onKeyDownMsgInput = (e, participantID) => {
        let value = e.target.value
        this.setState({ msgInput: value })
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13 && value) {
            this.sendMsg(participantID, value)
        }
    }

    // renderTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //       Simple tooltip
    //     </Tooltip>
    // );
    JSClock = (date) => {
        var time = new Date(date);
        var hour = time.getHours();
        var minute = time.getMinutes();
        // var second = time.getSeconds();
        var temp = ((hour < 10) ? '0' : ' ') + ((hour > 12) ? hour - 12 : hour);
        if (hour == 0)
            temp = '12';
        temp += ((minute < 10) ? ':0' : ':') + minute;
        // temp += ((second < 10) ? ':0' : ':') + second;
        temp += (hour >= 12) ? ' PM' : ' AM';
        temp += ' , ' + time.getDate() + '/' + (time.getMonth() + 1) + '/' + time.getFullYear()
        return temp;
    };

    render() {
        let { conv, participantName, participantID } = this.props;
        let { msgInput, messages, page, size, totalPage } = this.state;
        
        return (
            <>
                {this.state.isOpenMember
                    ?
                    <div >
                        <ChatMember
                            closeMemberListScreen={() => this.setState({ isOpenMember: false })}
                        // getLastConversation={this.getLastConversation}
                        >
                        </ChatMember>
                    </div>
                    :
                    null
                }
                {conv != null && participantName != null
                    ?
                    <div id="chat-screen">
                        <div className="content">
                            <div className="contact-profile">
                                <div>
                                    <img src="https://oasis.uet.vnu.edu.vn/api/resources/avatar/default.png" alt="" />
                                    {participantID != 0 && participantID != null ?
                                        <p><a href={"/#/profile/" + participantID} target="_blank">{participantName}</a></p>
                                        :
                                        <a onClick={() => {
                                            this.setState({ isOpenMember: true });
                                        }} style={{ background: "none", border: "none", cursor:"pointer" }}>{participantName}</a>
                                    }
                                </div>
                                <button onClick={this.openChatList} style={{ border: "none", outline: "none", background: "transparent", textAlign: "center" }}>
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                </button>
                            </div>
                            { page < totalPage ?
                            <a onClick={() => {this.getMsgs(participantID, page, size)}} style={{ background: "none", border: "none", cursor:"pointer", display: "block", textAlign: "center" }}>More...</a>
                            : <div></div>
                            }
                            <div className="messages" id="messages">
                                <ul>
                                    {messages.length > 0
                                        ?
                                        messages.map((msg, index) => {
                                            return (
                                                <li className="sent" key={index} className={classNames({ 'replies': msg.sendId == currentUserId }, { 'sent': msg.sendId != currentUserId })}>
                                                    <div>
                                                        <div>
                                                            {msg.sendId !== currentUserId ?
                                                                <div style={{ 'fontSize': '11px', display: 'flex', margin: '5px 5px 5px 10%' }}>{msg.name}</div> :
                                                                ''
                                                            }
                                                            <div>
                                                                <img src="https://oasis.uet.vnu.edu.vn/api/resources/avatar/default.png" alt="" />
                                                                <p style={{ cursor: 'pointer' }} data-for='custom-color-no-arrow' data-tip={this.JSClock(msg.createdAt)} data-event='click'>{msg.message}</p>
                                                            </div>
                                                            {/* <img src="http://oasis.uet.vnu.edu.vn/api/resources/avatar/default.png" alt="" />
                                                            <p style={{cursor: 'pointer'}} data-for='custom-color-no-arrow'data-tip={this.JSClock(msg.createdAt)} data-event='click'>{msg.message}</p> */}
                                                        </div>
                                                        <ReactTooltip key={index}
                                                            id='custom-color-no-arrow'
                                                            className='custom-color-no-arrow'
                                                            place="bottom"
                                                            getContent={(dataTip) => {
                                                                return dataTip;
                                                            }
                                                            }
                                                            delayHide={100}
                                                            border={false}
                                                            type={'dark'}
                                                            effect='solid'
                                                        />
                                                    </div>
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </ul>
                            </div>
                            <div className="message-input">
                                <div className="wrap">
                                    <input type="text" placeholder="Nhập tin nhắn..." value={msgInput} onChange={(e) => { this.setState({ msgInput: e.target.value }) }}
                                        onKeyDown={(e) => this.onKeyDownMsgInput(e, parseInt(participantID))}
                                    />
                                    <button onClick={() => this.sendMsg(parseInt(participantID), this.state.msgInput)} className="submit"><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    null
                }

            </>
        );
    }
}

export default ChatConversation;
