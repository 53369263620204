import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PickProblem from "../PickProblem/PickProblem";
import { getCurrentRoles } from '../../../core/GetRoles';

const PrivateTeacher = (...rest) => {
  var isAccess = false;
  const roles = getCurrentRoles();
  if (roles !== "LECTURE") {
    isAccess = false;
  } else {
    isAccess = true;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAccess ? (
          <PickProblem {...props} />
        ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
      }
    />
  )
};

export default PrivateTeacher;
