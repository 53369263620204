import React, { Component } from 'react';
import './Style.css';
import ChatConversation from './ChatConversation';
import { ListGroup, ListGroupItem } from 'reactstrap';
import axios from "axios/index";
import * as ApiConfig from '../../api/ApiConfig';
import { getUserId } from '../../core/GetRoles';
import lodash from 'lodash';
const currentUserId = getUserId();

var classNames = require('classnames');
var config = {
    headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') }
};

class ChatList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCloseChatList: false,
            searchConvInput: "",
            searchedConvs: [],
            clickedConv: null,
            lastestConvs: [],
            participantName: null,
            participantID: null
        }
    }

    componentDidMount() {
        this.getLastConversation()
    }

    getLastConversation = () => {
        let self = this;
        axios.get(ApiConfig.API_URL + '/chat/read-conversation', config)
            .then(function (res) {
                if (res.status == 200) {
                    self.setState({
                        lastestConvs: res.data
                    })
                }
            })
            .catch(err => console.log(err))
    }

    searchConv = lodash.debounce((value) => {
        let self = this;
        axios.get(ApiConfig.API_URL + `/chat/find-user?name=${value}&size=10`, config)
            .then(function (res) {
                self.setState({
                    searchedConvs: res.data.content
                })
            })
            .catch(err => console.log(err))
    }, 400)

    onChangeSearchConvInput = (e) => {
        let value = e.target.value;
        this.setState({
            searchConvInput: value
        })
        if (value.trim().length > 0) {
            this.searchConv(value)
        } else {
            this.setState({
                searchedConvs: []
            })
        }
    }

     clickOneSearchedConv(conv, participantName) {
        let participantID = null;
        // TH1: click các conv được lấy từ getLastConv
        if (conv.sendId && conv.receiverId) {
            participantID = this.getParticipantId(conv.sendId, conv.receiverId);
        }
        // TH2: click các conv được lấy từ search
        else if (conv.user_id) {
            participantID = conv.user_id;
        }
        this.setState({
            clickedConv: conv,
            searchedConvs: [],
            searchConvInput: '',
            isCloseChatList: true,
            participantName: participantName,
            participantID: participantID
        })
    }

    getParticipantId = (senderId, receiverId) => {
        if (senderId != currentUserId) return senderId;
        return receiverId;
    }

    render() {
        let { isCloseChatList, searchConvInput, searchedConvs,
            clickedConv, lastestConvs, participantName, participantID } = this.state;
        return (
            <div id="chat-screen">
                <div id="sidepanel" className={classNames({ 'd-none': isCloseChatList })}>
                    <div id="search">
                        <label for=""><i className="fa fa-search" aria-hidden="true"></i></label>
                        <input type="text" placeholder="Tìm kiếm" value={searchConvInput} onChange={this.onChangeSearchConvInput} />
                        {searchedConvs.length > 0
                            ?
                            <ListGroup className="position-absolute" style={{ zIndex: 9, width: "100%" }}>
                                {
                                    searchedConvs.map((conv) => {
                                        return (
                                            <ListGroupItem key={conv.user_id}
                                                style={{ color: 'black', padding: '16px', fontWeight: 600, cursor: 'pointer' }}
                                                onClick={() => this.clickOneSearchedConv(conv, conv.name)}>
                                                {conv.name}
                                            </ListGroupItem>
                                        )
                                    })
                                }
                            </ListGroup>
                            :
                            null
                        }
                    </div>
                    <div id="contacts">
                        <ul>
                            {lastestConvs.length > 0
                                ?
                                lastestConvs.map((conv, index) => {
                                    return (
                                        < li className="contact" key={index} onClick={() => {
                                            this.setState({ isCloseChatList: true });
                                            // this.clickOneSearchedConv(conv, this.getParticipantId(conv.sendId, conv.receiverId))
                                            this.clickOneSearchedConv(conv,conv.name||"")
                                        }} >
                                            <div className="wrap">
                                                <img src="https://oasis.uet.vnu.edu.vn/api/resources/avatar/default.png" alt="" />
                                                <div className="meta">
                                                    <p className="name">{conv.name}</p>
                                                    {conv.status == true ?
                                                    <p className="preview">{conv.message}</p> :
                                                    <p className="preview"><b>{conv.message}</b></p>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                null
                            }
                        </ul>
                    </div>
                </div>
                {isCloseChatList
                    ?
                    <div className={classNames('chat-conversation')}>
                        <ChatConversation conv={clickedConv}
                            participantName={participantName}
                            participantID={participantID}
                            openChatList={() => this.setState({ isCloseChatList: false })}
                            getLastConversation={this.getLastConversation}>
                        </ChatConversation>
                    </div>
                    :
                    null

                }
            </div >
        );
    }
}

export default ChatList;
