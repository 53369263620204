import React, { Component } from 'react';
import { Alert, Button, Card, CardBody, CardFooter, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import axios from 'axios';
import * as ApiConfig from './../../../api/ApiConfig';
import { getUserId } from '../../../core/GetRoles';

class UpdatePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			passOld: '',
			passNew: '',
			passCon: '',
			data: [],
			massagePassOld: '',
			massagePassNew: '',
			massagePassCon: '',
			colorNew: 'red',
			colorCon: 'red',
			massage: ''
		};

		this.handleChangePassOld = this.handleChangePassOld.bind(this);
		this.handleChangePassNew = this.handleChangePassNew.bind(this);
		this.handleChangePassCon = this.handleChangePassCon.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	// componentWillMount() {
		// if (localStorage.getItem('presentSemester') === null) {
		// 	new Promise((resolve, reject) => {
		// 		var config = {
		// 			headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') }
		// 		};
		// 		const userId = getUserId();
		// 		axios.get(ApiConfig.API_URL + '/users/' + userId + '/courses', config)
		// 			.then(function (response) {
		// 				response.data.semesters.map((s) => {
		// 					localStorage.setItem('presentSemester', s.semester_id);
		// 					s.course.map(c => {
		// 						localStorage.setItem('tempCourse_id', c.course_id);
		// 						localStorage.setItem('presentCourse_id', c.course_id);
		// 						// localStorage.setItem('presentCode', c.code);
		// 					})
		// 				});
		// 			})
		// 			.catch(function (error) {
		// 				console.log(error);
		// 			});
		// 	});
	// 	}
	// }

	handleChangePassOld = (event) => {
		this.setState({ passOld: event.target.value });
		if (event.target.value === '') {
			this.setState(
				{
					// massagePassOld: 'The old password is required and can not be empty!'
				}
			);
		} else {
			this.setState({
				massagePassOld: ''
			});
		}
	};
	handleChangePassNew = (event) => {
		this.setState({ passNew: event.target.value });
		if (event.target.value === this.state.passCon && event.target.value !== '') {
			this.setState({
				// massagePassCon: 'The password and confirm password is matched!',
				// massagePassNew: 'The password and confirm password is matched!',
				colorCon: 'green',
				colorNew: 'green'
			});
		} else if (event.target.value !== '') {
			this.setState({
				// massagePassCon: 'The password and confirm password must be match!',
				// massagePassNew: 'The password and confirm password must be match!',
				colorCon: 'red',
				colorNew: 'red'
			});
		} else {
			this.setState({
				// massagePassNew: 'The new password is required and can not be empty!',
				colorNew: 'red'
			});
		}
	};
	handleChangePassCon = (event) => {
		this.setState({ passCon: event.target.value });
		if (event.target.value === this.state.passNew && event.target.value !== '') {
			this.setState(
				{
					// massagePassCon: 'The password and confirm password is matched!',
					// massagePassNew: 'The password and confirm password is matched!',
					// colorCon: 'green',
					// colorNew: 'green'
				}
			);
		} else if (event.target.value !== '') {
			this.setState({
				// massagePassCon: 'The password and confirm password must be match!',
				// massagePassNew: 'The password and confirm password must be match!',
				colorCon: 'red',
				colorNew: 'red'
			});
		} else {
			this.setState({
				// massagePassCon: 'The confirm password is required and can not be empty!',
				colorCon: 'red'
			});
		}
	};

	handleSubmit = (event) => {
		let t = this;
		event.preventDefault();
		let currentComponent = this;
		if (this.state.passNew !== this.state.passCon) {
			this.setState({
				massagePassCon: 'The password and confirm password must be match!'
			});
		}
		if (this.state.passNew === '' || this.state.passOld === '' || this.state.passCon === '') {
			if (this.state.passNew === '') {
				this.setState({
					massagePassNew: 'The new password is required and can not be empty!'
				});
			}
			if (this.state.passOld === '') {
				this.setState({
					massagePassOld: 'The old password is required and can not be empty!'
				});
			}
			if (this.state.passCon === '') {
				this.setState({
					massagePassCon: 'The confirm password is required and can not be empty!'
				});
			}
		} else {
			return new Promise((resolve, reject) => {
				var data = {
					confirmPassword: this.state.passCon,
					oldPassword: this.state.passOld,
					newPassword: this.state.passNew
				};
				var headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
				};
				axios
					.post(ApiConfig.API_URL + '/users/reset-pass', data, { headers: headers })
					.then(function (response) {
						currentComponent.setState({ data: response });
						if (response.data.success === true) {
							window.location.replace('/');
						} else {
							t.setState({
								massage: 'Update failed !!!'
							});
						}
					})
					.catch(function (error) {
						console.log(error);
						t.setState({
							massage: 'Update failed !!!'
						});
					});
			});
		}
	};
	
	render() {
		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="6">
							<Card className="mx-4">
								<CardBody className="p-4">
									<Form onSubmit={this.handleSubmit}>
										{this.state.massage !== '' ? (
											<Alert color="danger">{this.state.massage}</Alert>
										) : null}
										<h1>Update</h1>
										<p className="text-muted">Change your password</p>
										<span style={{ color: 'red' }}>{this.state.massagePassOld}</span>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="icon-lock" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type="password"
												placeholder="Password"
												onChange={this.handleChangePassOld}
											/>
										</InputGroup>
										<span style={{ color: this.state.colorNew }}>{this.state.massagePassNew}</span>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="icon-lock" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type="password"
												placeholder="New Password"
												onChange={this.handleChangePassNew}
											/>
										</InputGroup>
										<span style={{ color: this.state.colorCon }}>{this.state.massagePassCon}</span>
										<InputGroup className="mb-4">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="icon-lock" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type="password"
												placeholder="Repeat new password"
												onChange={this.handleChangePassCon}
											/>
										</InputGroup>
										<Button color="success" block>
											Update Password
										</Button>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default UpdatePassword;
