import React from 'react';
import axios from "axios/index";
import * as ApiConfig from './../../../api/ApiConfig';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';


class ForgotPassword extends React.Component{
  constructor(props){
    super(props);
    this.state={
      user:'', massage:'', color:'primary'
    }
    this.getUser = this.getUser.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }
  getUser=(event)=>{
    this.setState({user: event.target.value})
  }
  resetPassword=(event)=>{
    event.preventDefault();
    let t = this;
    if(this.state.user != null){
      return new Promise((resolve , reject)=>{
        let data = {
          "username_or_email": this.state.user
        }

        axios.post(ApiConfig.API_URL +"/auth/forget-pass", data).then(res=> {
          if(res.data.success === true){
            t.setState({
              massage:'Password is sended to your email !!!', color:'primary'
            })
          }

        }).catch(function (error) {
          console.log(error);
          t.setState({
            massage:'Your email is invalid !!!', color:'danger'
          })
        });


      });

    }
  }

  render(){
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <Form onSubmit={this.resetPassword}>
                    {(this.state.massage!=='')?<Alert color={this.state.color}>
                      {this.state.massage}
                    </Alert>:null}
                    <h1>Forgot your password?</h1>
                    <p className="text-muted">Send new password to your email</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          @
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Your Email or Username" onChange={this.getUser}/>
                    </InputGroup>
                    <p>
                      Don't remember your email? &nbsp;
                      <a className="small text-primary" href="javascript:void(0)">Contact Support</a>.
                    </p>
                    <Row>
                      <Col>
                        <Button color="success" block>Update Password</Button>
                      </Col>
                      <Col>
                        <a href='/login' style={{textAlign:'right'}} color='primary'>Do you want to login?</a>
                      </Col>
                    </Row>
                  </Form>
                  <br/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default ForgotPassword;
