import React, { Component } from 'react';
import { Card, CardHeader, Row } from 'reactstrap';
import axios from "axios/index";
import * as ApiConfig from './../../../api/ApiConfig';
import Week from "./Week";
import { alertText, alertTextCustom } from '../../../core/Controller';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import bootbox from 'bootbox/dist/bootbox.min.js'


class PickProblem extends Component {
    constructor(props) {
        super(props);
        this.onEntering = this.onEntering.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.toggleCustom = this.toggleCustom.bind(this);
        this.toggleFade = this.toggleFade.bind(this);
        this.state = {
            loading: false,
            collapse: false,
            accordion: [true, false, false],
            custom: [true, false],
            status: 'Closed',
            fadeIn: true,
            timeout: 300,
            idweek: 0,
            folderpick: [],
            dataweek: []
        };
    }

    onEntering() {
        this.setState({ status: 'Opening...' });
    }

    onEntered() {
        this.setState({ status: 'Opened' });
    }

    onExiting() {
        this.setState({ status: 'Closing...' });
    }

    onExited() {
        this.setState({ status: 'Closed' });
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleAccordion(tab) {

        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);

        this.setState({
            accordion: state,
        });
    }

    toggleCustom(tab) {

        const prevState = this.state.custom;
        const state = prevState.map((x, index) => tab === index ? !x : false);

        this.setState({
            custom: state,
        });
    }

    toggleFade() {
        this.setState({ fadeIn: !this.state.fadeIn });
    }
    componentWillMount() {
        const self = this;
        var course_id = this.props.match.params.id;
        var config = {
            headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') }
        };
        // axios.get(ApiConfig.API_URL + "/problems/notInCourse?course_id=" + course_id + "&page=0&size=20", config)
        axios.get(ApiConfig.API_URL + "/courses/" + course_id + "/problems/not-in?page=0&size=20", config)
            .then(function (response) {
                self.setState({
                    folderpick: response.data.content
                })
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(ApiConfig.API_URL + '/courses/' + course_id + '/problems', config)
            .then(function (response) {
                self.setState({
                    dataweek: response.data.weeks,
                    idweek: response.data.weeks.length - 1
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    changeDeadLine = (id, time) => {
        this.state.dataweek[id].deadline = time.replace("Z", "+0000");
        this.setState({
            dataweek: this.state.dataweek
        }
        )
        // console.log(this.state.dataweek);
    }
    newWeek = () => {
        let time = new Date();
        if(this.state.dataweek.length >= 1){
            let index = this.state.dataweek.length - 1;
            let previousTime = new Date(this.state.dataweek[index].deadline.replace("+0000", "Z"));
            time = new Date(previousTime.getTime() + 604800000)
        }
        time.setHours(0);
        time.setMinutes(0);      
        this.state.dataweek.push({
            deadline: time.toISOString(),
            problems: []
        });
        this.setState(this.state);        
    }
    changeWeek = (id) => {
        this.setState({
            idweek: id
        })
    }
    removeItem = (idweek, iditem) => {
        let self = this;
        bootbox.confirm({
            message: "<h5 className='modal-title'>Bạn có muốn xóa bài tập này</h5>",
            buttons: {
                confirm: {
                    label: 'Xóa',
                    className: 'btn-primary'
                },
                cancel: {
                    label: 'Hủy',
                    className: 'btn-secondary'
                }
            },
            callback: function (result) {
                if (result == true) {
                    self.state.folderpick.push(self.state.dataweek[idweek].problems[iditem]);
                    self.state.dataweek[idweek].problems.splice(iditem, 1);
                    self.setState({
                        dataweek: self.state.dataweek,
                        folderpick: self.state.folderpick
                    });
                }
            }
        });
    }
    addItem = (iditem) => {
        this.state.dataweek[this.state.idweek].problems.push(this.state.folderpick[iditem]);
        this.state.folderpick.splice(iditem, 1);
        this.setState({
            folderpick: this.state.folderpick,
            dataweek: this.state.dataweek
        });
    }
    goBack = () => {
        this.props.history.push(`/course/${this.props.match.params.id}`);
    }
    save = () => {
        alertText("Loading...");
        this.setState({ loading: true });
        const weeks = this.state.dataweek.map(obj => {
            let week = obj.problems.map(e => {
                return e.problem_id
            })
            var rObj = {
                deadline: obj.deadline,
                problem_ids: week
            };
            return rObj;
        });
        var config = {
            headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') }
        };
        // var weeks=weeks;
        var self = this;
        var course_id = this.props.match.params.id;
        //TODO
        axios.post(ApiConfig.API_URL + '/courses/' + course_id + '/problems', { weeks }, config)
            .then(function (response) {
                alertTextCustom(response.data.message, '#28a745')
            })
            .catch(function (error) {
                alertText(error);
            })
            .then(function () {
                self.setState({ loading: false });
            });
    }
componentDidMount(){
    window.addEventListener('scroll', this.handleScroll)
}
componentWillUnmount (){
    window.removeEventListener('scroll', this.handleScroll)
}
handleScroll = ()=>{
    if(window.pageYOffset >= 70){
        document.getElementById("addweekId").style.top = "70px";
    }else{
        document.getElementById("addweekId").style.top = "125px"
    }
}
    render() {
        const color = ["linear-gradient(to right, #81c79b, #c3ffd0)", "linear-gradient(to right, #ffd194, #fbdfcf)", "linear-gradient(to right, #ff6b6b, #fbdfcf)"];
        const picks = this.state.folderpick.map((e, index) => {
            return <div onClick={() => this.addItem(index)} key={index} id={e.problem_id} style={{ "backgroundImage": color[e.level - 1] }} className="exercise"><i className="fa fa-plus-circle"></i>{e.title}</div>;
        })
        const weeks = this.state.dataweek.map((e, index) => {
            return <Week check={this.state.idweek} data={e} key={index} index={index}
                changeDeadLine={this.changeDeadLine}
                changeWeek={this.changeWeek}
                removeItem={this.removeItem}
                save={this.save}
            />;
        })

        return (

            <div id="pickproblem" className="animated fadeIn">
                <Row>
                    <div className="week col-xl-6">
                        {weeks}
                        <CardHeader onClick={this.newWeek} className="newweek">
                            <h6><i className="fa fa-plus-circle"></i> TẠO THÊM TUẦN</h6>
                        </CardHeader>
                    </div>

                    <div className="addweek col-xl-6" id="addweekId">
                        <button id="save-course" onClick={this.goBack} className="btn btn-primary btn-lg btn-block">
                            Quay về trang bài tập
                        </button>
                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"></i> Bài tập
                <div className="card-header-actions">
                                </div>
                            </CardHeader>
                            <div style={{ "padding": "0.75em", "maxHeight": "400px", "overflow": "scroll" }}>
                                {picks}
                            </div>
                        </Card>
                    </div>
                </Row>
            </div>
        );
    }
}


export default PickProblem;
