import React, { Component } from 'react';
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/dist/styles/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, Register } from './views/Pages';

// import { renderRoutes } from 'react-router-config';
import PrivateRoute from './views/Pages/PrivateRoute/PrivateRoute'
import ForgotPassword from "./views/Pages/ForgotPassword/ForgotPassword";
import PrivateTeacher from "./views/Pages/PrivateRoute/PrivateTeacher";
import PickProblem from "./views/Pages/PickProblem/PickProblem";
import Alert from './core/Alert';
import { setAlert } from './core/Controller';
import UpdatePassword from "./views/Pages/UpdatePassword";
import { getExp } from './core/GetRoles';

class App extends Component {
  constructor(props) {
    super(props);
    this.alert = React.createRef();
  }
  componentDidMount() {
    setAlert(this.alert);
  }
  render() {
    return (
      <div>
        <HashRouter basename="/">
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route exact path="/login" name="Login Page" render={(props) => {
                var isLoggedIn = false;
                const access_token = localStorage.getItem('access_token');
                if(access_token === ''){
                  isLoggedIn = false
                }else {
                  var date = new Date();
                  var time = date.getTime()/1000;
                  const EXP = getExp(access_token);
                  if(EXP > time){
                    isLoggedIn = true
                  }
                  else{
                    isLoggedIn = false
                    localStorage.setItem('access_token', '');
                  }
                }              
              return !isLoggedIn ? <Login {...props} /> : <Redirect to={'/dashboard'} />
            }} />
            <Route exact path="/forgot-password" name="Forgot Password Page" component={ForgotPassword} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
        <Alert text="none" ref={this.alert} />
      </div>


    );
  }
}

export default App;
