import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import axios from 'axios';
import * as ApiConfig from './../../../api/ApiConfig';
import { Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { alertText } from '../../../core/Controller';

import "./css/login.css"
// import "./css/login-02.css"
var request = require('request');
const pageToken = "EAAJh5s6bbt4BAEwmjPBhtPAgB9X6l6rI7ZCcrKYw6HRcbWNuNPqoPbn8dcgrTV51qwj1ojXW6snyZBZBUC5sPVY2mWlYfcbnuljesXMfo65EpvUFDEgJ3bvHZA2Lzyy3BrqQJI9NRlu4llsrTStZCIArqBZAcIghNGZBwEZCxd9bdQZDZD";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      pass: '', massage: '',
      verifyFB: false,
      tokenFb: null,
      // isValidTokenFb: true,
      verifyStatus: 0,
      duplicateFbid: false,
      nameUserFb: null
    }
  }
  componentDidMount = () => {
    let self = this;
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let tokenFb = params.get('tokenFb');
    if (tokenFb) {
      axios.get(ApiConfig.API_URL + '/resources/check-facebook?tokenFb=' + tokenFb)
        .then(res => {
          let status = res.data.httpStatus
          if (status == 200) {
            this.setState({
              verifyFB: true,
              tokenFb: tokenFb,
              verifyStatus: 0
            })
          } else if (status == 404) { // fb id not exist in database
            this.setState({
              verifyFB: true,
              tokenFb: tokenFb,
              verifyStatus: 0
            })
          } else if (res.data.httpStatus == 417) {
            this.setState({
              verifyFB: true,
              verifyStatus: 3
            })
          }
        })
        .catch(err => {
          this.setState({
            verifyFB: true,
            verifyStatus: 0
          })
        })
    }
  }
  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };
  inputName = (e) => {
    e.preventDefault();
    this.setState({
      name: e.target.value
    })
  };
  inputPass = (e) => {
    e.preventDefault();
    this.setState({
      pass: e.target.value
    })
  };

  sendBackMessage = (sender_psid) => {
    let response = {
      "text": `Xác thực thành công !`
    }
    this.callSendAPI(sender_psid, response);
  }
  getNameFacebook = (sender_psid) => {
    request({
      "uri": "https://graph.facebook.com/v4.0/" + sender_psid,
      "qs": { "access_token": pageToken },
      "method": "GET",
    }, (err, res, body) => {
      if (!err) {
        let data = JSON.parse(body);
        let name = data.first_name + ' ' + data.last_name;
        this.setState({
          nameUserFb: name
        })
      } else {
        console.error("Unable to send message:" + err);
      }
    });
  }

  callSendAPI = (sender_psid, response) => {
    let request_body = {
      "recipient": {
        "id": sender_psid
      },
      "message": response
    }

    request({
      "uri": "https://graph.facebook.com/v4.0/me/messages",
      "qs": { "access_token": pageToken },
      "method": "POST",
      "json": request_body
    }, (err, res, body) => {
      if (!err) {
        console.log('message sent!')
      } else {
        console.error("Unable to send message:" + err);
      }
    });
  }
  inputSubmit = (e) => {
    this.setState({
      verifyStatus: 1
    })
    let { verifyFB } = this.state;
    e.preventDefault();
    let t = this;
    if (this.state.name != null && this.state.pass != null) {
      return new Promise((resolve, reject) => {
        let data = {
          "password": this.state.pass,
          "username_or_email": this.state.name
        }
        axios.post(ApiConfig.API_URL + '/auth/signin', data).then(res => {
          console.log(res.data.access_token)
          if (res.data.access_token !== '') {
            // var j = this.parseJwt(res.data.access_token); // localStorage.setItem('id', JSON.parse(j.sub).id) // localStorage.setItem('roles', JSON.parse(j.sub).roles); // localStorage.setItem('is_lock', res.data.is_lock); // localStorage.setItem('exp', j.exp);
            localStorage.setItem('access_token', res.data.access_token);
            localStorage.setItem('token_type', res.data.token_type);
            if (verifyFB) { // if url contain tokenFb
              let { tokenFb } = this.state;
              var config = { headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') } };
              axios.get(ApiConfig.API_URL + '/auth/verify-facebook?tokenFb=' + tokenFb, config)
                .then( res => {
                  let status = res.data.httpStatus;
                  let tokenFbJwt = this.parseJwt(tokenFb);
                  let sender_psid = tokenFbJwt.fbId;
                  let oldFbId = res.data.oldFbId;
                  if (status == 200) {
                   this.setState({
                      verifyStatus: 2,
                      isValidTokenFb: true,
                    })
                    this.sendBackMessage(sender_psid);
                  } else if (status == 404) { // fbId not exist in tokenFb
                    this.setState({ verifyStatus: 3 })
                  } else if (status == 409) { // duplicate facebook id in database
                    this.setState({ duplicateFbid: true })
                    this.getNameFacebook(oldFbId);
                  }
                })
                .catch(err => { this.setState({ verifyStatus: 0 }) })
            }
            else {
              console.log(res.data.is_lock);
              if (res.data.is_lock === false) {
                window.location.replace("/");
              } else {
                window.location.replace("/#/update-password");
              }
            }
          } else {
            // t.setState({ massage: "Tên hoặc mật khẩu không đúng!!!", verifyStatus: 0 })
            t.setState({ verifyStatus: 0 });
            alertText("Tên hoặc mật khẩu không đúng!!!");
          }
        })
          .catch(er => {
            t.setState({ verifyStatus: 0 });
            let responseStatus = er.response.status;
            if (responseStatus < 500) {
              alertText("Tài khoản hoặc mật khẩu không đúng!!!");
            } else {
              alertText("Hệ thống đang bảo trì");
            }
          });
      });
    }
  };

  toggle = () => {
    this.setState({
      duplicateFbid: false,
      verifyStatus: 0
    })
  }

  onChangeFbAccount = (tokenFb) => {
    var config = { headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') } };
    axios.get(ApiConfig.API_URL + '/auth/change-facebook?tokenFb=' + tokenFb, config)
      .then(res => {
        this.setState({
          verifyStatus: 2,
          duplicateFbid: false
        })
        let tokenFbJwt = this.parseJwt(tokenFb);
        let sender_psid = tokenFbJwt.fbId;
        this.sendBackMessage(sender_psid);
      })
      .catch(err => console.log(err));
  }

  render() {
    let { verifyStatus, duplicateFbid, nameUserFb, tokenFb, verifyFB } = this.state;
    return (
      <div>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100 position-relative">
              <div className="d-flex justify-content-between align-items-center school-info" style={{ position: "absolute", width: "100%" }}>
                <div className="d-flex align-items-center">
                  <img src={require("./images/fit-uet.png")} className="mr-2" width="80" />
                  <div className="school-info-text">
                    <h2 id="site-title" style={{ fontWeight: "bold", paddingBottom: "0.45em" }}>KHOA CÔNG NGHỆ THÔNG TIN</h2>
                    <h4 id="site-description" className="text-muted" style={{ fontWeight: "normal" }}>Trường Đại học Công nghệ – Đại học Quốc gia Hà Nội</h4>
                  </div>
                </div>
                <img src={require("./images/oasis-logo.png")} className="oasis-logo-img" width="70" />
              </div>
              <div className="login100-pic js-tilt" data-tilt>
                <img src={require("./images/main-pic.png")} />
                <h4 className="text-center text-muted mt-4">Hệ thống hỗ trợ dạy và học môn Lập trình hương đối tượng</h4>
              </div>
              <Form onSubmit={this.inputSubmit} className="login100-form validate-form">
                {/* {(this.state.massage !== '') ? <Alert color="danger">
                  {this.state.massage}
                </Alert> : null} */}
                <span className="login100-form-title">
                  <img src={require("./images/uet-oasis.png")} className="mr-2" width="200" />
                </span>
                <p className="text-muted text-center pb-2">Sử dụng tài khoản bạn được cung cấp</p>
                <div className="wrap-input100 validate-input">
                  <Input type="text" placeholder="Tài khoản" className="input100" autoComplete="username" onChange={this.inputName} />
                  <span className="focus-input100"></span>
                  <span className="symbol-input100">
                    <i className="icon-user"></i>
                  </span>
                </div>
                <div className="wrap-input100 validate-input">
                  <Input type="password" className="input100" placeholder="Mật khẩu" autoComplete="current-password" onChange={this.inputPass} />
                  <span className="focus-input100"></span>
                  <span className="symbol-input100">
                    <i className="icon-lock"></i>
                  </span>
                </div>
                <Row className="justify-content-center">
                  <Col md="8" className="text-center">
                    {
                      verifyStatus == 0 ? <div className="container-login100-form-btn"><Button className="px-4 login100-form-btn">{verifyFB ? <span style={{ fontWeight: "600" }}>Xác nhận</span> : <span style={{ fontWeight: "600" }}>Đăng nhập</span>}</Button></div> :
                        verifyStatus == 1 ? <div className="spinner-border text-primary text-center"></div> :
                          verifyStatus == 2 ? <p className="mt-2" style={{ color: "green" }}><i className="fa fa-check"></i> Xác thực tài khoản thành công!</p> :
                            <div style={{ color: 'red' }}>Tài khoản Facebook không tồn tại</div>
                    }
                    <b>
                      <a href="https://drive.google.com/file/d/1mgCu5tuFXfdcsWekxTzqnNOv88ItaV_A/view?fbclid=IwAR1_fVTDj1TmqnP1FHxqlZiUDW_MF8piTuBu2ZyTkPteyPovMCWEEnA9bW8">Hướng dẫn sử dụng dành cho sinh viên</a>
                    </b>
                  </Col>
                  {/* <Col xs="6" className="text-right">
                    <a style={{ color: 'primary' }} href="/forgot-password" className="px-0">Quên mật khẩu?</a>
                  </Col> */}
                </Row>
                <div className="text-center p-t-136">
                  <a className="txt2" href="#">
                    {/* Create your Account */}
                    {/* <i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i> */}
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </div>


        <Modal isOpen={duplicateFbid}>
          <ModalBody>
            Tài khoản của bạn đang được tài khoản Facebook <b>{nameUserFb}</b> sử dụng. Bạn có muốn chuyển tài khoản Facebook ?
        </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.onChangeFbAccount(tokenFb)}>Xác nhận</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Hủy</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Login;
