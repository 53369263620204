import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import { getExp } from '../../../core/GetRoles';

const PrivateRoute = ({ component: Component, ...rest }) => {
  var isLoggedIn = false;
  const access_token = localStorage.getItem('access_token');
  if(access_token === ''){
    isLoggedIn = false
  }else {
    var date = new Date();
    var time = date.getTime()/1000;
    const EXP = getExp(access_token);
    if(EXP > time){
      isLoggedIn = true
    }
    else{
      isLoggedIn = false
      localStorage.setItem('access_token', '');
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
};

export default PrivateRoute;
