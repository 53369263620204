import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';
import UpdatePassword from "./views/Pages/UpdatePassword";
import PrivateTeacher from "./views/Pages/PrivateRoute/PrivateTeacher";

function Loading() {
  return (
    <div className="sk-wave">
      <div className="sk-rect sk-rect1"></div>
      <div className="sk-rect sk-rect2"></div>
      <div className="sk-rect sk-rect3"></div>
      <div className="sk-rect sk-rect4"></div>
      <div className="sk-rect sk-rect5"></div>
    </div>
  )
}

const Breadcrumbs = Loadable({
  loader: () => import('./views/Base/Breadcrumbs'),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import('./views/Base/Cards'),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import('./views/Base/Carousels'),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import('./views/Base/Collapses'),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import('./views/Base/Dropdowns'),
  loading: Loading,
});

const Forms = Loadable({
  loader: () => import('./views/Base/Forms'),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import('./views/Base/Jumbotrons'),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import('./views/Base/ListGroups'),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import('./views/Base/Navbars'),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import('./views/Base/Navs'),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import('./views/Base/Paginations'),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import('./views/Base/Popovers'),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import('./views/Base/ProgressBar'),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import('./views/Base/Switches'),
  loading: Loading,
});

const Tables = Loadable({
  loader: () => import('./views/Base/Tables'),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import('./views/Base/Tabs'),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import('./views/Base/Tooltips'),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import('./views/Buttons/BrandButtons'),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import('./views/Buttons/ButtonDropdowns'),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import('./views/Buttons/ButtonGroups'),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import('./views/Buttons/Buttons'),
  loading: Loading,
});

const Charts = Loadable({
  loader: () => import('./views/Charts'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const CoreUIIcons = Loadable({
  loader: () => import('./views/Icons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('./views/Icons/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('./views/Icons/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('./views/Icons/SimpleLineIcons'),
  loading: Loading,
});

const Alerts = Loadable({
  loader: () => import('./views/Notifications/Alerts'),
  loading: Loading,
});

const Badges = Loadable({
  loader: () => import('./views/Notifications/Badges'),
  loading: Loading,
});

const Modals = Loadable({
  loader: () => import('./views/Notifications/Modals'),
  loading: Loading,
});

const Colors = Loadable({
  loader: () => import('./views/Theme/Colors'),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import('./views/Theme/Typography'),
  loading: Loading,
});

const Widgets = Loadable({
  loader: () => import('./views/Widgets/Widgets'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./views/Users/Users'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./views/Pages/UpdatePassword'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./views/Profile/Profile'),
  loading: Loading,
});

const CodingPage = Loadable({
  loader: () => import('./views/CodingPage/index'),
  loading: Loading,
});

const Lesson = Loadable({
  loader: () => import('./views/Pages/Lesson/Lesson'),
  loading: Loading,
});

const PickProblem = Loadable({
  loader: () => import('./views/Pages/PickProblem/PickProblem'),
  loading: Loading,
});

const Ranking = Loadable({
  loader: () => import('./views/Ranking/index'),
  loading: Loading,
});

const Submissions = Loadable({
  loader: () => import('./views/Submissions/Submissions'),
  loading: Loading,
});

const Statistic = Loadable({
  loader: () => import('./views/Statistic/Statistic'),
  loading: Loading,
});

const DetailHistory = Loadable({
  loader: () => import('./views/Submissions/index'),
  loading: Loading,
});
const ManageCourse = Loadable({
  loader: () => import('./views/ManageCourse/ManageCourse'),
  loading: Loading,
});

const CheckSourceCode = Loadable({
  loader: () => import('./views/CheckSourceCode/index'),
  loading: Loading,
});

const CompareResultForOneProblem = Loadable({
  loader: () => import('./views/CheckSourceCode/CompareResultForOneProblem'),
  loading: Loading,
});

const CompareDetailForOneFile = Loadable({
  loader: () => import('./views/CheckSourceCode/CompareDetailForOneFile'),
  loading: Loading,
});

const CompareResultInTableFormat = Loadable({
  loader: () => import('./views/CheckSourceCode/CompareResultInTableFormat/CompareResultInTableFormat'),
  loading: Loading,
});

const OptionsForResultInTableFormat = Loadable({
  loader: () => import('./views/CheckSourceCode/CompareResultInTableFormat/OptionsForResultInTableFormat'),
  loading: Loading,
});

const PullError = Loadable({
  loader: () => import('./views/CheckSourceCode/CompareResultInTableFormat/PullError'),
  loading: Loading,
});

const ChatScreen = Loadable({
  loader: () => import('./views/ChatScreen/index'),
  loading: Loading,
});

const CreateTeam = Loadable({
  loader: () => import('./views/CreateTeam/CteateTeam'),
  loading: Loading,
});

//admin
const StudentManage = Loadable({
  loader: () => import('./views/Admin/StudentManage'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Trang chủ', component: DefaultLayout },
  { path: '/dashboard', name: 'Lớp học phần', component: Dashboard },
  { path: '/update-password', name: 'Update Password', component: UpdatePassword },
  { path: '/profile/:id', name: 'Trang cá nhân', component: Profile },
  { path: '/codingPage/:cid/:title/:id', exact: true, name: 'Bài tập', component: CodingPage },
  { path: '/codingPage/:cid/:title/:id/tab/:active_tab', exact: true, name: 'Bài tập', component: CodingPage },
  { path: '/theme', exact: true, name: 'Theme', component: Colors },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', exact: true, name: 'Base', component: Cards },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/forms', name: 'Forms', component: Forms },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/users', exact: true, name: 'Users', component: Users },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },
  { path: '/course/:id', name: 'Bài tập', component: Lesson },
  { path: '/teacher/pickproblem/:id', name: 'Pick Problem', component: PrivateTeacher },
  { path: '/ranking', name: 'Xếp hạng', component: Ranking },
  { path: '/submissions', name: 'Bài nộp', component: Submissions },
  { path: '/submissions_detail/:sub_id/:form/:title', name: 'Chi tiết bài nộp', component: DetailHistory },
  { path: '/statistic', name: 'Thống kê', component: Statistic },
  { path: '/manage-course', name: 'Quản lý lớp học', component: ManageCourse },
  { path: '/check-sourcecode', exact: true, name: 'Kiểm tra mã nguồn', component: CheckSourceCode },
  { path: '/check-sourcecode/problem/:problem_id', exact: true, name: 'Chi tiết', component: CompareResultForOneProblem },
  { path: '/check-sourcecode/problem/:problem_id/file/:filename/:avgPercent', exact: true, name: 'Chi tiết', component: CompareDetailForOneFile },
  { path: '/check-sourcecode/problem/:problem_id/table', exact: true, name: 'Kết quả dạng bảng', component: OptionsForResultInTableFormat },
  { path: '/check-sourcecode/problem/:problem_id/error', exact: true, name: 'Pull lỗi', component: PullError },
  { path: '/chat-screen', name: 'Chat', component: ChatScreen },
  { path: '/create-team/:course_id/:course_problem_id',name: 'Tạo nhóm' ,component: CreateTeam},

  //admin
  { path: '/admin/studentmanage', name: 'Submissions', component: StudentManage }

];

export default routes;
