import React, { Component } from 'react';
import './Style.css';
import ChatConversation from './ChatConversation';
import { ListGroup, ListGroupItem } from 'reactstrap';
import axios from "axios/index";
import * as ApiConfig from '../../api/ApiConfig';
import { getUserId } from '../../core/GetRoles';
import lodash from 'lodash';
const currentUserId = getUserId();

var classNames = require('classnames');
var config = {
    headers: { 'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token') }
};

class ChatMember extends Component {

    constructor(props) {
        super(props);
        this.state = {
            members: []
        }
    }

    componentDidMount() {
        let self = this;
        axios.get(ApiConfig.API_URL + `/lecturers?`, config)
            .then(function (res) {
                self.setState({
                    members: res.data.content
                })
            })
            .catch(err => console.log(err))
    }

    openMember() {
        this.props.openMember()
    }

    render() {
        let { conv, participantName, participantID } = this.props;
        let { members } = this.state;
        return (
            <div id="chat-screen">
                    <div className="content">
                        <div className="contact-profile">
                            <div>
                                <img src="https://oasis.uet.vnu.edu.vn/api/resources/avatar/default.png" alt="" />
                                <p>Danh sách thành viên</p>
                            </div>
                            <button onClick={this.props.closeMemberListScreen} style={{ border: "none", outline: "none", background: "transparent" }}>
                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                        </div>
                    <div id="sidepanel" >
                    <div id="contacts">
                        <ul>
                            {members.length > 0
                                ?
                                members.map((mb, index) => {
                                    return (
                                        < li className="contact" key={index}  >
                                            <div className="wrap">
                                                <img src="https://oasis.uet.vnu.edu.vn/api/resources/avatar/default.png" alt="" />
                                                <div className="meta">
                                                    <p className="preview">{mb.name}</p> 
                                                    <p className="preview"><b>{mb.username}</b></p>
                                                </div>
                                            </div>
                                        </li>
                                          
                                    )
                                })
                                :
                                null
                            }
                        </ul>
                    </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ChatMember;
